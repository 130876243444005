import React from 'react';
import styled from 'styled-components';
import { MdLocationOn, MdLocalPhone, MdMailOutline } from 'react-icons/md';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Wrapper = styled.section`
  padding: 2rem;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 768px) {
    padding: 4rem;
  }

  @media screen and (min-width: 1024px) {
    flex: 0 0 40rem;
  }

  @media screen and (min-width: 1200px) {
    flex: 0 0 45rem;
  }

  @media screen and (min-width: 1600px) {
    flex: 0 0 50rem;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;

  @media screen and (min-width: 768px) {
    padding: 1rem;
  }

  svg {
    font-size: 4.5rem;
    margin: 1rem;
    flex: 0 0 auto;

    @media screen and (min-width: 768px) {
      font-size: 5.5rem;
    }

    @media screen and (min-width: 1024px) {
      font-size: 4.5rem;
    }

    @media screen and (min-width: 1200px) {
      font-size: 5rem;
    }
  }

  p,
  a {
    color: white;
    display: block;

    @media screen and (min-width: 768px) {
      font-size: 2rem;
    }

    @media screen and (min-width: 1024px) {
      font-size: 1.6rem;
    }

    @media screen and (min-width: 1200px) {
      font-size: 1.7rem;
    }
  }
`;

interface Props {
  contactInfo: {
    address: {
      street: string;
      city: string;
      country: string;
      mapLink: string;
    };
    phones: { phone1: string; phone2: string };
    email: string;
  };
}

const ContactInfo: React.FC<Props> = ({ contactInfo }) => {
  const { street, city, country, mapLink } = contactInfo.address;
  const { phone1, phone2 } = contactInfo.phones;
  const { email } = contactInfo;
  return (
    <Wrapper>
      <article>
        <Item>
          <MdLocationOn />
          <div>
            <OutboundLink
              href={mapLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {street}
            </OutboundLink>
            <OutboundLink
              href={mapLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {city}
            </OutboundLink>
            <OutboundLink
              href={mapLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {country}
            </OutboundLink>
          </div>
        </Item>
        <Item>
          <MdLocalPhone />
          <div>
            <OutboundLink href={`tel:${phone1}`}>{phone1}</OutboundLink>
            <OutboundLink href={`tel:${phone2}`}>{phone2}</OutboundLink>
          </div>
        </Item>
        <Item>
          <MdMailOutline />
          <div>
            <OutboundLink
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {email}
            </OutboundLink>
          </div>
        </Item>
      </article>
    </Wrapper>
  );
};

export default ContactInfo;
