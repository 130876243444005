import React from 'react';
import { Layout, SEO } from '../../common';
import HomeHero from './homeHero';

interface Props {
  preview?: boolean;
  title: string;
  primaryColor: string;
}

const HomePage: React.FC<Props> = ({ preview, title, primaryColor }) => {
  return (
    <Layout color={primaryColor} noFooter preview={preview}>
      {!preview ? <SEO title="Home" /> : null}
      <HomeHero preview={preview} title={title} />
    </Layout>
  );
};

export default HomePage;
