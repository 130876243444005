import React from 'react';
import { FaRegHeart } from 'react-icons/fa';
import { FiBookOpen, FiAward } from 'react-icons/fi';
import { Layout, SEO, Hero, Cards, Banner } from '../../common';
import OurStory from './ourStory';

const pageData = {
  primaryColor: '#BA5624',
  secondaryColor: '#311212',
};

export interface Props {
  preview?: boolean;
  data: {
    primaryColor: string;
    secondaryColor: string;
    title: string;
    subtitle: string;
    heroText: string;
    cardTitle1: string;
    cardText1: string;
    cardTitle2: string;
    cardText2: string;
    cardTitle3: string;
    cardText3: string;
    paragraph1: string;
    paragraph2: string;
  };
}

const AboutPage: React.FC<Props> = ({ data, preview }) => {
  const {
    primaryColor,
    secondaryColor,
    title,
    subtitle,
    heroText,
    cardTitle1,
    cardText1,
    cardTitle2,
    cardText2,
    cardTitle3,
    cardText3,
    paragraph1,
    paragraph2,
  } = data;

  const cardsData = [
    { title: cardTitle1, text: cardText1, children: <FaRegHeart /> },
    { title: cardTitle2, text: cardText2, children: <FiBookOpen /> },
    { title: cardTitle3, text: cardText3, children: <FiAward /> },
  ];

  return (
    <Layout color={primaryColor} footerColor={secondaryColor} preview={preview}>
      {!preview ? <SEO title="About" /> : null}
      <Hero title={title} subTitle={subtitle} text={heroText} novideo />
      <Cards cardsData={cardsData} color={pageData.primaryColor} small />
      <Banner title="our story" color={pageData.secondaryColor} />
      <OurStory text1={paragraph1} text2={paragraph2} />
    </Layout>
  );
};
export default AboutPage;
