import React from 'react';
import styled from 'styled-components';

const StyledSection = styled.section`
  padding: 1.5rem 0;

  @media screen and (min-width: 768px) {
    padding: 3.5rem 0;
  }

  @media screen and (min-width: 1024px) {
    padding: 6.5rem 0;
  }

  @media screen and (min-width: 1200px) {
    padding: 8.5rem 0;
  }

  @media screen and (min-width: 1600px) {
    padding: 7rem 0;
  }

  p {
    margin: 4rem auto;
    line-height: 2rem;
    width: 85%;
    text-indent: 5rem;

    @media screen and (min-width: 768px) {
      line-height: 3rem;
      font-size: 2rem;
      text-indent: 8rem;
      padding: 2rem 0;
    }

    @media screen and (min-width: 1200px) {
      line-height: 3.5rem;
      font-size: 2.2rem;
      text-indent: 10rem;
      padding: 3rem 0;
    }

    @media screen and (min-width: 1600px) {
      line-height: 4.8rem;
      font-size: 2.4rem;
      text-indent: 12rem;
    }
  }
`;

interface Props {
  text1: string;
  text2: string;
}

const OurStory: React.FC<Props> = ({ text1, text2 }) => {
  return (
    <StyledSection>
      <p>{text1}</p>
      <p>{text2}</p>
    </StyledSection>
  );
};

export default OurStory;
