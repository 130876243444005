import React from 'react';
import { FluidObject } from 'gatsby-image';
import { Layout, SEO, Hero } from '../../common';
import ContactUs from './contactUs';
import video from '../../../media/videos/contact.mp4';

interface Props {
  preview?: boolean;
  data: {
    primaryColor: string;
    secondaryColor: string;
    title: string;
    subTitle: string;
    heroText: string;
    fluid: FluidObject;
    street: string;
    city: string;
    country: string;
    mapLink: string;
    phone1: string;
    phone2: string;
    email: string;
  };
}

const ContactPage: React.FC<Props> = ({ preview, data }) => {
  const {
    primaryColor,
    secondaryColor,
    title,
    subTitle,
    heroText,
    fluid,
    street,
    city,
    country,
    mapLink,
    phone1,
    phone2,
    email,
  } = data;

  const contactInfo = {
    address: { street, city, country, mapLink },
    phones: { phone1, phone2 },
    email,
  };
  return (
    <Layout color={primaryColor} footerColor={secondaryColor} preview={preview}>
      {!preview ? <SEO title="Contact" /> : null}
      <Hero
        title={title}
        subTitle={subTitle}
        text={heroText}
        video={video as string}
        poster={fluid}
        preview={preview}
      />
      <ContactUs bannerColor={secondaryColor} contactInfo={contactInfo} />
    </Layout>
  );
};

export default ContactPage;
