import React from 'react';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-flow: column;
  width: 90%;
  padding: 2rem;
  margin: 3rem auto;

  @media screen and (min-width: 768px) {
    width: 70%;
  }

  @media screen and (min-width: 1024px) {
    margin: 0;
    flex: 0 0 40rem;
  }

  @media screen and (min-width: 1200px) {
    flex: 0 0 45rem;
  }

  @media screen and (min-width: 1600px) {
    flex: 0 0 50rem;
  }

  input,
  textarea {
    margin: 1rem;
    padding: 0.8rem;
    background: transparent;
    border: 0.2rem solid white;
    color: white;
    letter-spacing: 0.1rem;

    &::placeholder {
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.3rem;
      padding-left: 0.5rem;
    }

    @media screen and (min-width: 1200px) {
      font-size: 1.4rem;
    }
  }

  input:nth-child(1) {
    text-transform: capitalize;
  }

  textarea {
    height: 10rem;
  }

  button {
    background: transparent;
    color: white;
    text-transform: uppercase;
    font-size: 1.6rem;
    width: 14rem;
    height: 3.5rem;
    margin: 1rem auto;
    border: 0.2rem solid white;
  }
`;

const ContactForm: React.FC = () => {
  return (
    <Form name="contact" method="POST" action="https://formspree.io/xlewyzvr">
      <input type="text" placeholder="full name" name="full name" id="name" />
      <input type="email" placeholder="Email" name="email" id="email" />
      <textarea placeholder="message" name="message" id="message" />
      <button type="submit">send message</button>
    </Form>
  );
};

export default ContactForm;
