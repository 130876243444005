import React from 'react';
import styled from 'styled-components';
import { Banner } from '../../common';
import ContactInfo from './contactInfo';
import ContactForm from './contactForm';

const Wrapper = styled.div`
  @media screen and (min-width: 1024px) {
    height: 50rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const BannerMobile = styled(Banner)`
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;
const BannerDesktop = styled(Banner)`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

interface Props {
  bannerColor: string;
  contactInfo: {
    address: { street: string; city: string; country: string; mapLink: string };
    phones: { phone1: string; phone2: string };
    email: string;
  };
}

const ContactUs: React.FC<Props> = ({ bannerColor, contactInfo }) => {
  return (
    <section>
      <BannerDesktop title="contact us" color={bannerColor} />
      <BannerMobile title="get in touch" color={bannerColor} />
      <Wrapper>
        <ContactInfo contactInfo={contactInfo} />
        <BannerMobile title="send us a message" color={bannerColor} />
        <ContactForm />
      </Wrapper>
    </section>
  );
};

export default ContactUs;
