import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Link } from 'gatsby';
import { svg } from '../../common';
import MainImage from './mainImage';

const { Pointer } = svg;

const Wrapper = styled.section`
  min-height: calc(
    100vh - 22.9rem
  ); /* 12.9rem Header size + 10rem to avoid page expansion on android */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 1024px) {
    min-height: 60rem;
    flex-flow: row nowrap;
    position: relative;
    justify-content: center;
  }
`;

const StyledPointer = styled(Pointer)`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    width: 17rem;
    margin-bottom: 11rem;
  }

  @media screen and (min-width: 1200px) {
    width: 23.9rem;
  }
`;

const CtaWrapper = styled.div`
  @media screen and (min-width: 1024px) {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 1200px) {
    margin-bottom: 4rem;
  }

  @media screen and (min-width: 1600px) {
    margin-bottom: 2rem;
  }
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  word-spacing: 0.2rem;
  width: 32rem;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    font-size: 3rem;
    word-spacing: 0.9rem;
    width: 43.5rem;
    margin-bottom: 15rem;
  }

  @media screen and (min-width: 1024px) {
    font-size: 2.5rem;
    width: 34rem;
    margin-bottom: 0;
  }

  @media screen and (min-width: 1600px) {
    font-size: 3.2rem;
    width: 42rem;
  }
`;

const CtaButton = styled.button`
  background: transparent;
  border: 0.3rem solid white;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-size: 1.7rem;
  padding: 1rem 2rem;
  border-radius: 1rem;
  display: block;
  margin: 5rem auto;

  a {
    color: white;
    text-decoration: none;
  }

  @media screen and (min-width: 1600px) {
    font-size: 2.5rem;
    padding: 1.2rem 2.4rem;
  }
`;

interface Props {
  preview?: boolean;
  title?: string;
}

const HomeHero: React.FC<Props> = ({ preview, title }) => {
  return (
    <Wrapper>
      <MainImage />
      <StyledPointer />
      <CtaWrapper>
        <HeroTitle>{title}</HeroTitle>
        <CtaButton>
          {!preview ? (
            <AniLink paintDrip hex="#253C78" to="/services">
              explore more
            </AniLink>
          ) : (
            <Link to="/services">explore more</Link>
          )}
        </CtaButton>
      </CtaWrapper>
    </Wrapper>
  );
};

export default HomeHero;
