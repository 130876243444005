import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { HomePage } from '../components/pages';

const LandingPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { eq: "homePage" } }) {
        frontmatter {
          title
          primaryColor
        }
      }
    }
  `);

  const { title, primaryColor } = data.markdownRemark.frontmatter;

  return <HomePage primaryColor={primaryColor} title={title} />;
};

export default LandingPage;
